html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

address {
  margin-bottom: 0;
  font-style: normal;
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

:root {
  --bg-color: #182633;
  --rand-color: #434455;
  --white-color: #fff;
  --yellow-color: #fcc300;
  --title-color: #2e2f42;
  --border-color: #e7e9fc;
  --bg-modal: #fcfcfc;
  --bg-cards-swiper: #212d45;
  --bs-gray: #6c757d;
}

body {
  touch-action: manipulation;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
}

.bg-body-img {
  height: 850px;
  background-image: linear-gradient(rgba(24, 38, 51, .7), rgba(24, 38, 51, .7)), url("factory-workers-work-wear-yellow-helmets-walking-through-industrial-production-hall_148840-10360.842820e2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no-scroll {
  overflow: hidden;
}

.header {
  color: var(--white-color);
  height: 80px;
  width: 100%;
  z-index: 1000;
  border: 0;
  border-bottom: 2px solid rgba(235, 235, 235, .24);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
}

.container-header {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.header-navigation {
  justify-content: center;
  align-items: center;
  margin-right: auto;
  display: flex;
}

.logo-link {
  width: 70px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-navigation-list {
  list-style: none;
  display: none;
}

nav ul li {
  margin: 0;
}

.header-list-address {
  justify-content: center;
  align-items: center;
  list-style: none;
  display: flex;
}

.logo-link-img {
  fill: #ff0;
}

.header-link {
  letter-spacing: .02em;
  width: 100%;
  color: var(--white-color);
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  transition: color .2s;
}

.header-item {
  transition: all .3s;
}

.header-link:hover, .header-link:focus, .header-link:active {
  color: var(--yellow-color);
}

.header-item:hover, .header-item:active, .header-item:focus {
  transform: scale(1.1);
}

.header-address-link {
  color: var(--white-color);
  text-decoration: none;
  transition: color .3s;
  display: none;
}

.contact-info a {
  transition: all .2s;
}

.contact-info a:hover, .contact-info:focus, .contact-info:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.hamburger {
  color: var(--white-color);
  cursor: pointer;
  background: none;
  border: none;
  font-size: 2rem;
  display: flex;
}

.mobile-menu {
  width: 250px;
  height: 100%;
  background-color: var(--bg-color);
  color: var(--white-color);
  z-index: 1000;
  flex-direction: column;
  padding: 20px;
  transition: right .3s;
  display: flex;
  position: fixed;
  top: 0;
  right: -250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.mob-svg-menu {
  fill: #c6cdd1;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transition-property: transform;
  transition-duration: .3s;
  display: flex;
}

.mobile-menu.active {
  right: 0;
}

.mobile-menu .close-btn {
  color: var(--white-color);
  cursor: pointer;
  background: none;
  border: none;
  align-self: flex-end;
  margin-bottom: 20px;
  font-size: 2rem;
}

.mobile-menu ul {
  padding: 0;
  list-style: none;
}

.mobile-menu ul li {
  margin-bottom: 20px;
}

.mobile-menu ul li a {
  color: var(--white-color);
  font-size: 1.2rem;
  text-decoration: none;
}

.mobile-menu ul li a:hover {
  text-decoration: underline;
}

.mobile-menu .contact-info {
  margin-top: auto;
}

.mob-menu-address-link {
  color: var(--white-color);
  letter-spacing: .04em;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}

.mob-menu-list-svg-menu {
  gap: 14px;
  margin-top: 30px;
  display: flex;
}

.mobile-menu .contact-info a:hover {
  text-decoration: underline;
}

main {
  margin: 0 auto;
}

.main-section-one {
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  padding-top: 112px;
  padding-bottom: 112px;
}

.main-title {
  color: var(--yellow-color);
  margin-bottom: 48px;
  font-size: 36px;
  line-height: 1.07;
}

.main-text {
  color: var(--white-color);
  letter-spacing: .02em;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
}

.main-button {
  height: 50px;
  width: 200px;
  background-color: var(--yellow-color);
  color: var(--yellow-color);
  background: none;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-right: 200px;
  font-size: 16px;
  transition: all .5s;
  display: flex;
}

.main-button:hover, .main-button:focus, .main-button:active {
  color: var(--white-color);
  transform: scale(1.1);
  box-shadow: -1px 1px 39px -2px rgba(252, 195, 0, .8);
}

.container-button {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.current {
  display: none;
}

.container-two-title {
  color: var(--title-color);
  margin-bottom: 5px;
  font-size: 40px;
  line-height: 1.07;
}

.container-two-subtitle {
  color: #9b9d9e;
  font-size: 12px;
}

.container-two-item {
  width: 350px;
}

.container-two-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 20px;
  margin-top: 30px;
  list-style: none;
  display: flex;
}

.container-two-text {
  color: var(--title-color);
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.main-section-two {
  padding-top: 80px;
  padding-bottom: 80px;
}

.container-two-item-img {
  height: 235px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container-two-item-img > img {
  height: 100%;
  border-radius: 15px;
}

.main-section-three {
  background-color: rgba(192, 192, 192, .2);
  padding-top: 80px;
  padding-bottom: 80px;
}

.container-three-item {
  width: 350px;
  background-color: var(--white-color);
  cursor: pointer;
  border: 3px solid var(--white-color);
  border-radius: 30px;
  padding: 20px;
  transition: all .5s;
}

.container-three-item:active, .container-three-item:focus, .container-three-item:hover .container-three-img {
  transform: translateY(-50px);
}

.container-three-item:hover, .container-three-item:active, .container-three-item:focus {
  box-shadow: 0 -1px 53px -7px rgba(18, 17, 17, .75);
}

.container-three-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 30px;
  margin-top: 50px;
  list-style: none;
  display: flex;
}

.container-three-cards-btn {
  justify-content: center;
  display: flex;
}

.container-three-cards-title {
  color: var(--rand-color);
  margin-top: 20px;
  font-size: 20px;
}

.container-three-cards-text {
  color: var(--rand-color);
}

.container-three-cards-part-text {
  height: 100px;
}

.three-cards-btn {
  height: 40px;
  width: 90px;
  color: #fff;
  background-color: rgba(0, 0, 0, .9);
  border: 1px solid #fff;
  border-radius: 20px;
  outline: none;
  margin-top: 5px;
  font-size: 18px;
  transition: all .5s;
}

.three-cards-btn:hover {
  color: #000;
  border: 1px solid ease;
  background-color: #fff;
  border-color: #000;
}

.container-three-img {
  justify-content: center;
  margin-bottom: 10px;
  transition: all .5s;
  display: flex;
  position: relative;
}

.main-section-four {
  padding-top: 80px;
  padding-bottom: 80px;
}

.swiper {
  height: 500px;
}

.container-four-title {
  justify-content: center;
  align-items: center;
  display: flex;
}

.container-four-title > h2 {
  color: var(--title-color);
  font-size: 40px;
  line-height: 1.07;
}

.swiper-button-prev {
  color: var(--border-color);
}

.swiper-button-prev:after {
  font-size: 20px;
}

.swiper-button-next {
  color: var(--border-color);
}

.swiper-button-next:after {
  font-size: 20px;
}

.swiper-pagination-bullet-active {
  background: var(--bg-cards-swiper);
}

.container-four-swiper-slider {
  justify-content: center;
  display: flex;
}

.container-four-img {
  margin-right: 20px;
}

.container-four-swiper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.container-four-cards-swiper {
  height: 200px;
  background-color: var(--bg-cards-swiper);
  border-radius: 50px;
  justify-content: center;
  padding: 50px 50px 0;
  display: flex;
}

.container-four-swiper-text {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 12px;
}

.container-four-swiper-name {
  color: var(--yellow-color);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.container-four-swiper-city {
  color: var(--white-color);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.contact-form {
  background-color: rgba(192, 192, 192, .2);
  padding-top: 100px;
  padding-bottom: 50px;
}

.contact-title {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 25px;
}

.contact-text {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 14px;
}

.contact-text:last-child {
  margin-bottom: 40px;
}

.container-contact-form {
  background: var(--bg-cards-swiper);
  border-radius: 50px;
  padding: 30px;
}

.form {
  width: 100%;
  justify-content: center;
  align-items: end;
  display: flex;
}

input, textarea {
  width: 100%;
  border: 1px solid var(--white-color);
  background: (192, 192, 192, .1);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  transition: all .3s;
}

.contact-form input:focus, .contact-form textarea:focus {
  border-color: var(--bg-cards-swiper);
  outline: none;
  transform: scale(1.02);
}

.btn {
  color: var(--white-color);
  background-color: var(--yellow-color);
  cursor: pointer;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  padding: 15px;
  font-size: 14px;
  transition: all .3s;
  display: flex;
}

.btn:hover {
  color: #000;
  transform: scale(1.1);
}

.container-near-form {
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.text-near-form {
  color: var(--bs-gray);
  letter-spacing: .02em;
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
}

.title-near-form {
  color: var(--title-color);
  margin-bottom: 10px;
  font-size: 16px;
}

.container-near-form-list {
  list-style: none;
}

.address {
  margin-top: 40px;
  margin-bottom: 40px;
}

footer {
  background: var(--bg-cards-swiper);
  padding-top: 40px;
  padding-bottom: 20px;
}

.footer-row {
  margin-bottom: 40px;
}

.footer-svg-menu {
  cursor: pointer;
  fill: #c6cdd1;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  transition-property: transform;
  transition-duration: .3s;
  display: flex;
}

.footer-list-svg-menu {
  list-style: none;
}

.footer-svg-menu:hover, .footer-svg-menu:focus {
  fill: var(--yellow-color);
  transform: scale(1.2);
}

.footer-text {
  letter-spacing: -.02em;
  color: #c6cdd1;
  font-size: 14px;
  line-height: 1.6;
}

.footer-title-text {
  color: var(--yellow-color);
  margin-top: 20px;
  margin-bottom: 10px;
}

.footer-text:hover {
  color: #c6cdd1;
}

.footer-box {
  display: flex;
}

.current {
  margin-bottom: 30px;
}

.footer-address-email {
  letter-spacing: .02em;
  color: #c6cdd1;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  transition: all .3s;
  display: inline-block;
}

.footer-address-email:hover, .footer-address-email:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-address-phone {
  letter-spacing: .02em;
  color: #c6cdd1;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  transition: all .3s;
  display: block;
}

.footer-address-phone:hover, .footer-address-phone:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-address-link {
  letter-spacing: .02em;
  color: #c6cdd1;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  transition: all .3s;
  display: inline-block;
}

.section-footer-addres-link {
  margin-top: 20px;
}

.footer-address-link:hover, .footer-address-link:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-list-svg-menu {
  gap: 14px;
  margin-bottom: 20px;
  display: flex;
}

.footer-nav {
  margin-left: auto;
  display: flex;
}

.footer-address {
  transition: all .3s;
}

.footer-address:hover, .footer-address:active {
  color: var(--yellow-color);
  transform: scale(1.2);
}

.footer-menu-item:not(:last-child) {
  margin-bottom: 14px;
}

.footer-address-item:not(:last-child) {
  margin-bottom: 20px;
}

.text-list {
  gap: 14px;
  margin-top: 14px;
  list-style: none;
  display: flex;
}

.footer-text-bottom {
  display: block;
}

.privat {
  flex-direction: column-reverse;
  display: flex;
}

#backToTop {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all .3s;
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 20px;
}

.svg-backToUp {
  transition: all .3s;
}

.svg-backToUp:hover {
  fill: var(--yellow-color);
}

#backToTop:hover {
  transform: scale(1.3);
}

/*# sourceMappingURL=index.41dcfd21.css.map */
