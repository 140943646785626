html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

address {
  font-style: normal;
  margin-bottom: 0px;
}

ul {
  margin: 0px;
  padding-left: 0px;
  list-style: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

:root {
  --bg-color: rgb(24, 38, 51);
  --rand-color: #434455;
  --white-color: #ffffff;
  --yellow-color: rgb(252, 195, 0);
  --title-color: #2e2f42;
  --border-color: #e7e9fc;
  --bg-modal: #fcfcfc;
  --bg-cards-swiper: #212d45;
  --bs-gray: #6c757d;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

.bg-body-img {
  height: 850px;
  background-image: linear-gradient(
      rgba(24, 38, 51, 0.7),
      rgba(24, 38, 51, 0.7)
    ),
    url(/src/images/background_img/factory-workers-work-wear-yellow-helmets-walking-through-industrial-production-hall_148840-10360.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* ------------HEADER------------ */

.no-scroll {
  overflow: hidden;
}

.header {
  color: var(--white-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  border: 2px solid rgba(235, 235, 235, 0.24);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.header-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
}

.logo-link {
  width: 70px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-navigation-list {
  display: none;
  list-style: none;
}

nav ul li {
  margin: 0;
}

.header-list-address {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.logo-link-img {
  fill: yellow;
}

.header-link {
  text-decoration: none;
  padding-top: 24px;
  padding-bottom: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  width: 100%;
  color: var(--white-color);
  transition: color 0.2s ease;
}

.header-item {
  transition: all 0.3s ease;
}

.header-link:hover,
.header-link:focus,
.header-link:active {
  color: var(--yellow-color);
}

.header-item:hover,
.header-item:active,
.header-item:focus {
  transform: scale(1.1);
}

.header-address-link {
  display: none;
  color: var(--white-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a {
  transition: all 0.2s;
}

.contact-info a:hover,
.contact-info:focus,
.contact-info:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.hamburger {
  display: flex;
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--white-color);
  cursor: pointer;
}

/* ----------------------------MOBILE-SECTION------------------------ */

.mobile-menu {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: var(--bg-color);
  color: var(--white-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1000;
}

.mob-svg-menu {
  display: flex;

  justify-content: center;
  align-items: center;

  fill: #c6cdd1;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff1a;
  border-radius: 10px;

  transition-property: transform;
  transition-duration: 0.3s;
}

.mobile-menu.active {
  right: 0;
}

.mobile-menu .close-btn {
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--white-color);
  cursor: pointer;
  margin-bottom: 20px;
  align-self: flex-end;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu ul li {
  margin-bottom: 20px;
}

.mobile-menu ul li a {
  color: var(--white-color);
  text-decoration: none;
  font-size: 1.2rem;
}

.mobile-menu ul li a:hover {
  text-decoration: underline;
}

.mobile-menu .contact-info {
  margin-top: auto;
}

.mob-menu-address-link {
  color: var(--white-color);
  text-decoration: none;
  letter-spacing: 0.04em;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.mob-menu-list-svg-menu {
  gap: 14px;

  display: flex;
  margin-top: 30px;
}

.mobile-menu .contact-info a:hover {
  text-decoration: underline;
}

/* Main Content */
main {
  margin: 0 auto;
}

.main-section-one {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  margin-left: auto;
  margin-right: auto;

  padding-top: 112px;
  padding-bottom: 112px;
}

.main-title {
  font-size: 36px;
  line-height: 1.07;
  margin-bottom: 48px;
  color: var(--yellow-color);
}
.main-text {
  color: var(--white-color);
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.main-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid white;
  height: 50px;
  width: 200px;
  background-color: var(--yellow-color);
  margin-top: 40px;
  font-size: 16px;
  background: transparent;
  color: var(--yellow-color);
  transition: all 0.5s ease;
  margin-right: 200px;
}

.main-button:hover,
.main-button:focus,
.main-button:active {
  color: var(--white-color);
  transform: scale(1.1);
  box-shadow: -1px 1px 39px -2px rgba(252, 195, 0, 0.8);
  -webkit-box-shadow: -1px 1px 39px -2px rgba(252, 195, 0, 0.8);
  -moz-box-shadow: -1px 1px 39px -2px rgba(252, 195, 0, 0.8);
}

.container-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.current {
  display: none;
}

/* ----------------------------SECTION TWO---------------------------------- */

.container-two-title {
  font-size: 40px;
  line-height: 1.07;
  margin-bottom: 5px;
  color: var(--title-color);
}

.container-two-subtitle {
  font-size: 12px;
  color: rgb(155, 157, 158);
}

.container-two-item {
  width: 350px;
}

.container-two-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  gap: 10px 20px;
  margin-top: 30px;
}

.container-two-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: var(--title-color);
  padding-top: 10px;
  padding-bottom: 20px;
}

.main-section-two {
  padding-top: 80px;
  padding-bottom: 80px;
  /* background: #fbfbfb; */
}
.container-two-item-img {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 235px;
}

.container-two-item-img > img {
  border-radius: 15px;
  height: 100%;
}

/* ------------------------------SECTION THREE------------------------------------ */

.main-section-three {
  background-color: rgb(192, 192, 192, 0.2);
  padding-top: 80px;
  padding-bottom: 80px;
}

.container-three-item {
  width: 350px;

  background-color: var(--white-color);

  transition: all 0.5s ease;
  border-radius: 30px;

  cursor: pointer;
  padding: 20px;

  border: 3px solid var(--white-color);
}

.container-three-item:active,
.container-three-item:focus,
.container-three-item:hover .container-three-img {
  transform: translateY(-50px);
}

.container-three-item:hover,
.container-three-item:active,
.container-three-item:focus {
  box-shadow: 0px -1px 53px -7px rgba(18, 17, 17, 0.75);
  -webkit-box-shadow: 0px -1px 53px -7px rgba(18, 17, 17, 0.75);
  -moz-box-shadow: 0px -1px 53px -7px rgba(18, 17, 17, 0.75);
}

.container-three-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  gap: 20px 30px;
  margin-top: 50px;
}

.container-three-cards-btn {
  display: flex;
  justify-content: center;
}

.container-three-cards-title {
  color: var(--rand-color);
  font-size: 20px;
  margin-top: 20px;
}

.container-three-cards-text {
  color: var(--rand-color);
}

.container-three-cards-part-text {
  height: 100px;
}

.three-cards-btn {
  height: 40px;
  width: 90px;
  margin-top: 5px;
  border-radius: 20px;
  background-color: rgb(0, 0, 0, 0.9);
  font-size: 18px;

  color: white;
  border: 1px solid white;
  outline: none;

  transition: all 0.5s ease;
}

.three-cards-btn:hover {
  color: black;
  background-color: white;
  border: 1px solid ease;
  border-color: black;
}

.container-three-img {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  transition: all 0.5s ease;
}

.main-section-four {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* ------------------------------SECTION SWIPER------------------------------------ */
.swiper {
  height: 500px;
}

.container-four-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-four-title > h2 {
  font-size: 40px;
  color: var(--title-color);
  line-height: 1.07;
}

.swiper-button-prev {
  color: var(--border-color);
}

.swiper-button-prev::after {
  font-size: 20px;
}
.swiper-button-next {
  color: var(--border-color);
}

.swiper-button-next::after {
  font-size: 20px;
}

.swiper-pagination-bullet-active {
  background: var(--bg-cards-swiper);
}

.container-four-swiper-slider {
  display: flex;
  justify-content: center;
}

.container-four-img {
  margin-right: 20px;
}

.container-four-swiper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-four-cards-swiper {
  padding: 50px 50px 0px 50px;
  display: flex;
  justify-content: center;

  border-radius: 50px;
  height: 200px;
  background-color: var(--bg-cards-swiper);
}

.container-four-swiper-text {
  color: var(--white-color);
  font-size: 12px;
  margin-bottom: 10px;
}

.container-four-swiper-name {
  color: var(--yellow-color);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.container-four-swiper-city {
  font-size: 14px;
  color: var(--white-color);
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

.contact-form {
  background-color: rgb(192, 192, 192, 0.2);
  padding-top: 100px;
  padding-bottom: 50px;
}

/* ------------------------------SECTION CONTACT------------------------------------ */

.contact-title {
  color: var(--white-color);
  font-size: 25px;
  margin-bottom: 10px;
}

.contact-text {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 10px;
}
.contact-text:last-child {
  margin-bottom: 40px;
}

.container-contact-form {
  border-radius: 50px;
  background: var(--bg-cards-swiper);

  padding: 30px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--white-color);
  background: (192, 192, 192, 0.1);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: var(--bg-cards-swiper);
  outline: none;
  transform: scale(1.02);
}

.btn {
  display: flex;
  margin: 0 auto;
  padding: 15px;
  font-size: 14px;
  color: var(--white-color);
  background-color: var(--yellow-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  color: black;
  transform: scale(1.1);
}

.container-near-form {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.text-near-form {
  color: var(--bs-gray);
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  padding-left: 20px;
}
.title-near-form {
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--title-color);
}

.container-near-form-list {
  list-style: none;
}

/*-------------------------------- FOOTER -----------------------------------------*/

.address {
  margin-top: 40px;
  margin-bottom: 40px;
}
footer {
  background: var(--bg-cards-swiper);
  padding-top: 40px;
  padding-bottom: 20px;
}

.footer-row {
  margin-bottom: 40px;
}

.footer-svg-menu {
  cursor: pointer;
  fill: #c6cdd1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition-property: transform;
  transition-duration: 300ms;
}

.footer-list-svg-menu {
  list-style: none;
}
.footer-svg-menu:hover,
.footer-svg-menu:focus {
  fill: var(--yellow-color);
  transform: scale(1.2);
}
.footer-text {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.02em;
  color: #c6cdd1;
}

.footer-title-text {
  color: var(--yellow-color);
  margin-bottom: 10px;
  margin-top: 20px;
}
.footer-text:hover {
  color: #c6cdd1;
}
.footer-box {
  display: flex;
}

.current {
  margin-bottom: 30px;
}

.footer-address-email {
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #c6cdd1;
  transition: all 0.3s ease;

  display: inline-block;
}

.footer-address-email:hover,
.footer-address-email:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-address-phone {
  /* display: block; */
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #c6cdd1;
  transition: all 0.3s ease;

  display: inline-block;
  display: block;
}
.footer-address-phone:hover,
.footer-address-phone:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-address-link {
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #c6cdd1;
  transition: all 0.3s ease;

  display: inline-block;
}

.section-footer-addres-link {
  margin-top: 20px;
}

.footer-address-link:hover,
.footer-address-link:active {
  color: var(--yellow-color);
  transform: scale(1.1);
}

.footer-list-svg-menu {
  display: flex;
  gap: 14px;
  margin-bottom: 20px;
}
.footer-nav {
  display: flex;
  margin-left: auto;
}

.footer-address {
  transition: all 0.3s ease;
}

.footer-address:hover,
.footer-address:active {
  transform: scale(1.2);
  color: var(--yellow-color);
}
.footer-menu-item:not(:last-child) {
  margin-bottom: 14px;
}
.footer-address-item:not(:last-child) {
  margin-bottom: 20px;
}
.text-list {
  display: flex;
  margin-top: 14px;
  gap: 14px;
  list-style: none;
}

.footer-text-bottom {
  display: block;
}

.privat {
  display: flex;
  flex-direction: column-reverse;
}

/* ------------------------Back to Top Button --------------*/
#backToTop {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 20px;

  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  /* color: var(--bg-cards-swiper); */
}

.svg-backToUp {
  transition: all 0.3s ease;
}

.svg-backToUp:hover {
  fill: var(--yellow-color);
}

#backToTop:hover {
  transform: scale(1.3);
}
